import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import {
  // changeQ,
  selectCurrQ,
  selectQuestions,
  // selectPoints,
  // nextQ,
  updatePoints,
  selectFloorMenu,
  selectIsFinished
} from '../../store/questionSlice'
import { useHistory } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import QuestionDisplay from '../../Components/QuestionDisplay/QuestionDisplay'
import PageHeader from '../../Components/PageHeader/PageHeader'
const hasBar = true
const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    }
  }
}

function QuestionScreen ({ openMenu }) {
  const currQ = useSelector(selectCurrQ)
  const qState = useSelector(selectQuestions)
  const isFinished = useSelector(selectIsFinished)
  useEffect(() => {
    if (!qState || !qState.questions) history.push('/')
    if (isFinished) {
      history.push('final')
    }
  }, [isFinished])
  const floor = qState.currentFloor
  // const points = useSelector(selectPoints)
  const floorMenu = useSelector(selectFloorMenu)
  const dispatch = useDispatch()
  const currQData = qState.questions[floor].questions[currQ]
  // const currFloorLen = qState.questions[floor].questions.length
  const currType = floorMenu[floor].questions[currQ].type
  const history = useHistory()

  function upPoints (pts) {
    let qType = false
    switch (currType) {
      case 'mcq-single':
      case 'mcq-multi':
      case 'classify':
        qType = 'question'
        break
      case 'photobooth':
        qType = 'photobooth'
        break
      default:
        break
    }
    dispatch(updatePoints({ pts, qType }))
  }
  if (currType === 'photobooth') {
    document.body.classList.add('photoBg')
  } else {
    document.body.classList.remove('photoBg')
  }
  const { title, type } = floorMenu[floor].questions[currQ]
  const direction = 1
  return (
        <>
          <div className='pagesContainer'>
            {hasBar && <PageHeader qNum={currQ + 1} title={title} type={type} openMenu={openMenu} />}
            <AnimatePresence initial={false} custom={direction}>
                  <motion.div
                    variants={variants}
                    initial="exit"
                    custom={direction}
                    animate="center"
                    exit="exit"
                    className='pageCont'
                    transition={{
                      x: { type: 'spring', stiffness: 300, damping: 30 },
                      opacity: { duration: 0.2 }
                    }}
                    key={`page-${currQ}`}>
                    <PageCont pages={currQData} updatePoints={upPoints}/>
                  </motion.div>
            </AnimatePresence>
          </div>
        </>
  )
}

function PageCont ({ pages, updatePoints }) {
  const [qPoints, setQpoints] = useState(0)
  const [currPage, setCurrPage] = useState(0)
  const cPageData = pages[currPage]
  const [isOver, setOver] = useState(false)
  const [success, setSuccess] = useState(0)
  function nextPage () {
    if (currPage < pages.length - 1) {
      setCurrPage(currPage + 1)
    } else setOver(true)
  }

  return <QuestionDisplay {...cPageData}
    setOver={setOver}
    nextPage={nextPage}
    setSuccess={setSuccess}
    points={qPoints}
    setQpoints={setQpoints}
    updatePoints={updatePoints}
    success={success}
    isOver={isOver}
    />
}

QuestionScreen.propTypes = {
  openMenu: PropTypes.func.isRequired
}

PageCont.propTypes = {
  pages: PropTypes.array.isRequired,
  updatePoints: PropTypes.func.isRequired
}

export default QuestionScreen
