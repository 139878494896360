import { createSlice } from '@reduxjs/toolkit'
import { MIN_PTS } from '../data/constants'

import { prepareData } from '../utils'

const initialState = {
  currentQuestion: 0,
  currentFloor: 0,
  test: true,
  currPage: 0,
  points: 0,
  userQs: 0,
  userPbs: 0,
  quizzFinished: false,
  shouldGetMorePts: false,
  customFb: false,
  isQuizzReady: false
  // maxQ: stateData.questions[0].totalQ,
  // questions: stateData.questions,
  // onBoarding: stateData.onBoarding,
  // finalResult: stateData.finalResult,
  // floorMenu: stateData.floorMenu,
  // totalQs: stateData.totalQs.qs,
  // totalPbs: stateData.totalQs.fbs
}

export const slice = createSlice({
  name: 'q',
  initialState,
  reducers: {
    prepareQ: (state, action) => {
      const stateData = prepareData(action.payload)
      state.maxQ = stateData.questions[0].totalQ
      state.questions = stateData.questions
      state.onBoarding = stateData.onBoarding
      state.finalResult = stateData.finalResult
      state.floorMenu = stateData.floorMenu
      state.totalQs = stateData.totalQs.qs
      state.totalPbs = stateData.totalQs.fbs
      state.isQuizzReady = true
    },
    changeQ: (state, action) => {
      state.currentQuestion = action.payload
    },
    updatePoints: (state, action) => {
      console.log('point update', action.payload)
      const { pts, qType } = action.payload
      state.floorMenu[state.currentFloor].questions[state.currentQuestion].isFinished = true
      state.points = state.points + pts
      if (qType) {
        if (qType === 'question') ++state.userQs
        else if (qType === 'photobooth') ++state.userPbs
      }
    },
    setCustomFb: (state, action) => {
      state.customFb = action.payload
    },
    changeFloor: (state, action) => {
      if (state.currentFloor === action.payload) return // do nothing if floor and dest floor are the same
      // disble old floor management
      // else if (state.floorMenu[state.currentFloor].isFinished === false && (action.payload > state.currentFloor)) { // floor is higher than previous one
      //   state.floorMenu[state.currentFloor].isFinished = true
      //   state.floorMenu[state.currentFloor].questions = state.floorMenu[state.currentFloor].questions.map((qs) => {
      //     qs.isFinished = true
      //     return qs
      //   })
      //   state.currentQuestion = 0
      //   state.maxQ = stateData.questions[action.payload].totalQ
      // }
      if (!state.floorMenu[action.payload].isFinished) state.currentFloor = action.payload
    },
    updateQ: (state, action) => {
    },
    nextQ: (state, action) => {
      if ((state.currentQuestion + 1) < state.maxQ) {
        state.floorMenu[state.currentFloor].questions[state.currentQuestion].isFinished = true
        state.currentQuestion = state.currentQuestion + 1
      } else if (state.currentFloor < state.floorMenu.length - 1) {
        // state.floorMenu[state.currentFloor].questions = state.floorMenu[state.currentFloor].questions.map((qs) => {
        //   qs.isFinished = true
        //   return qs
        // })
        state.floorMenu[state.currentFloor].questions[state.currentQuestion].isFinished = true
        // state.floorMenu[state.currentFloor].isFinished = true
        console.log('floor switch', state.floorMenu[state.currentFloor])
        state.currentQuestion = 0
        state.maxQ = state.questions[state.currentFloor + 1].totalQ
        state.currentFloor = state.currentFloor + 1
      } else {
        console.log('you reached the end')
        if (state.points >= MIN_PTS) {
          for (let index = 0; index < state.floorMenu.length; index++) {
            state.floorMenu[index].questions = state.floorMenu[index].questions.map((qs) => {
              qs.isFinished = true
              return qs
            })
          }
          state.quizzFinished = true
        } else {
          state.shouldGetMorePts = true
        }
      }
    }
  }
})

export const { changeQ, updatePoints, changeFloor, nextQ, setCustomFb, prepareQ } = slice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCurrQ = state => {
  return state.questions.currentQuestion
}

export const selectCurrFloor = state => {
  return state.questions.currentFloor
}
export const selectCustomFb = state => {
  return state.questions.customFb
}
export const selectFloorMenu = state => {
  return state.questions.floorMenu
}

export const selectPoints = state => {
  return state.questions.points
}
export const selectTotalQs = state => {
  return {
    tqs: state.questions.totalQs,
    tPbs: state.questions.totalPbs,
    uQs: state.questions.userQs,
    uPbs: state.questions.userPbs
  }
}

export const selectQuestions = state => {
  return state.questions
}

export const selectOnboarding = state => {
  return state.questions.onBoarding
}
export const selectIsFinished = state => {
  return state.questions.quizzFinished
}

export const selectIsQuizzReady = state => {
  return state.questions.isQuizzReady
}

export default slice.reducer
