import React from 'react'
import PropTypes from 'prop-types'
import './menuFootButton.css'
import Icon from '../Icon/Icon'
export default function MenuFootButton ({ type, text, size, openPref }) {
  return (
    <button
      type="button"
      className={['menuFootButton', `button--${size}`].join(' ')}
      onClick={openPref}
    >
      <Icon type={type} />
      <span className='footText'> {text} </span>
    </button>
  )
}

MenuFootButton.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
  openPref: PropTypes.func.isRequired
}

MenuFootButton.defaultProps = {
  size: 'medium'
}
