import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon'
import QIcon from '../QIcon/QIcon'
import { useDispatch } from 'react-redux'
import { changeQ } from '../../store/questionSlice'
import './menuItem.css'

const MenuItem = ({ qTitle, type, isLocked, qNum, qIndex, close, current, isFinished, questionNum }) => {
  const dispatch = useDispatch()
  function changeQuestion () {
    dispatch(changeQ(qIndex))
    close()
  }

  return (
      <button
        disabled={isFinished}
        className={['menuItem', isLocked ? 'isLocked' : '', current ? 'currentQ' : ''].join(' ')}
        onClick={changeQuestion}
      >
        {(type === 'mcq-multi' || type === 'mcq-single' || type === 'mcq-custom' || type === 'classify') && <QIcon num={questionNum} isFinished={isFinished} current={current}/>}
        {type === 'photobooth' && <Icon type='camera' current={current}/>}
        {type === 'info' && <QIcon num='!' current={current} />}
        {/* {type === 'info' && <Icon type='info' />} */}
        <span className='questionText'> {qTitle} </span>
      </button>
  )
}

export default MenuItem

MenuItem.propTypes = {
  qTitle: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isLocked: PropTypes.bool.isRequired,
  qIndex: PropTypes.number.isRequired,
  questionNum: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
  current: PropTypes.bool.isRequired,
  isFinished: PropTypes.bool.isRequired,
  qNum: PropTypes.number.isRequired
}
