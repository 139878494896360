import React from 'react'
import Mcq from '../../QComps/Mcq/Mcq'
// import Classify from '../../QComps/Classify/Classify'
import FillIn from '../../QComps/FillIn/Fillin'
import Classify from '../../Components/Classify/Classify'
import PropTypes from 'prop-types'
import PageContainer from '../../Components/PageContainer/PageContainer'
import NameSelection from '../../Components/NameSelection/NameSelection'
// import sampleMcq from '../../data/sampleMcqSingle.json'
// import sampleClassify from '../../data/sampleClassify.json'
import { useDispatch, useSelector } from 'react-redux'
import { selectOS } from '../../store/appSlice'
import { nextQ } from '../../store/questionSlice'
import Page from '../../QComps/Page/Page'
import ResultPage from '../ResultPage/ResultPage'
import Photobooth from '../../QComps/Photobooth/Photobooth'

function QuestionDisplay (props) {
  const dispatch = useDispatch()
  const os = useSelector(selectOS)
  function nextQuestion () {
    dispatch(nextQ())
  }
  const { _component, nextPage, success, setQpoints, updatePoints, nextQOb, kname } = props
  switch (_component) {
    case 'page':
    case 'info':
      return (
        <PageContainer>
          <Page {...props} nextQuestion={nextQOb || nextQuestion} kname={kname}/>
        </PageContainer>
      )
    case 'name':
      return (
        <PageContainer>
          <NameSelection {...props} />
        </PageContainer>
      )
    case 'photoPage':
      return (
        <PageContainer>
          <Page {...props} nextQuestion={nextQuestion} os={os}/>
        </PageContainer>
      )
    case 'mcq-multi':
    case 'mcq-single':
    case 'mcq-custom':
      return (
        <PageContainer>
          <Mcq {...props} nextQuestion={nextQuestion}/>
        </PageContainer>
      )
    case 'photobooth':
      return (
        <PageContainer>
          <Photobooth {...props} nextQuestion={nextQuestion}/>
        </PageContainer>
      )
    case 'fillin':
      return (
        <PageContainer>
          <FillIn {...props}/>
        </PageContainer>
      )
    case 'classify':
      return (
      <PageContainer>
        <Classify {...props} act1={nextPage} setQpoints={setQpoints} updatePoints={updatePoints}/>
      </PageContainer>)
    case 'result':
      return (
        <PageContainer>
          <ResultPage success={success} {...props} nextQuestion={nextQOb || nextQuestion}/>
        </PageContainer>
      )
    default:
      return <div>unknown type {_component}</div>
  }
}

QuestionDisplay.propTypes = {
  // points: PropTypes.number.isRequired,
  setQpoints: PropTypes.func.isRequired,
  _component: PropTypes.string.isRequired,
  nextPage: PropTypes.func,
  success: PropTypes.number.isRequired,
  updatePoints: PropTypes.func.isRequired,
  points: PropTypes.number.isRequired,
  _items: PropTypes.array,
  kname: PropTypes.string,
  nextQOb: PropTypes.func
}

export default QuestionDisplay
