import React from 'react'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import MenuItem from '../MenuItem/MenuItem'
import './FloorMenu.css'

const containerVariants = {
  hidden: { opacity: 1 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 50
    }
  }
}

const menuItemVariants = {
  hidden: { opacity: 0 },
  show: { opacity: 1 }
}

const FloorMenu = ({ menuData, close, currQ }) => {
  let menuQItems = 1
  return (
    <motion.div
      initial="hidden"
      className="floorMenu"
      animate="show"
      variants={containerVariants}
    >
      <div className="menContainer">
        { menuData && menuData.map((eData, index) => {
          const cNum = menuQItems
          if (eData.type === 'mcq-multi' || eData.type === 'mcq-single' || eData.type === 'mcq-custom' || eData.type === 'classify') {
            ++menuQItems
          }
          return (
            <motion.div
              initial="hidden"
              animate="show"
              variants={menuItemVariants}
              key={`menuitem-${index}`}
              >
              <MenuItem
                qIndex={index}
                qTitle={eData.title}
                questionNum={parseInt(cNum)}
                current={currQ === index}
                type={eData.type}
                isLocked={eData.isFinished}
                close={close}
                isFinished={eData.isFinished}
                qNum={eData.position} />
            </motion.div>
          )
        })
        }
      </div>
    </motion.div>
  )
}

export default FloorMenu

FloorMenu.propTypes = {
  menuData: PropTypes.array.isRequired,
  currQ: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired
}
