import React, { useState } from 'react'
import { motion } from 'framer-motion'
// import styled from 'styled-components'`
import { useSelector } from 'react-redux'
import { selectLang, selectCommonKeys } from '../../store/appSlice'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon'

import Button from '../../Components/Button/Button'
import QIcon from '../QIcon/QIcon'
import './Profile.css'
import { MIN_PTS, RAFFLE_URL } from '../../data/constants'

import Hint from '../Hint/Hint'
import disc from '../../imgs/discount.png'

const prefVariant = {
  open: { x: 0, position: 'fixed' },
  closed: { x: '-100%', position: 'absolute' }
}

const Profile = ({ prefOpen, closePref, alt1, qTotal, qFinished, fTotal, fFinished, cta1act, cta2act, points }) => {
  const lang = useSelector(selectLang)
  const certPath = `/pct/certThumb_${lang}.jpg`
  const [certHintOpen, setCertHintOpen] = useState(false)
  const [hintText, sethintText] = useState(false)
  const cmn = useSelector(selectCommonKeys)

  function openRaffle () {
    window.open(RAFFLE_URL, '_blank')
  }

  function toggleHint () {
    setCertHintOpen(!certHintOpen)
  }

  function changePopup (pop) {
    switch (pop) {
      case 'cert':
        sethintText({
          ttl: cmn.your_certificate,
          body: cmn.cert_body,
          pct: certPath,
          cta1txt: cmn.download,
          dlUrl: `/pct/cert_${lang}.jpg`
        })
        toggleHint()
        break
      case 'discount':
        sethintText({
          ttl: cmn.your_qr,
          body: cmn.qr_body,
          pct: disc,
          cta1txt: null,
          dlUrl: null
        })
        toggleHint()
        break
      default:
        break
    }
  }

  return (
    <motion.div
        className="overlayCont prefOverlay"
        animate={prefOpen ? 'open' : 'closed'}
        transition={{ type: 'tween' }}
        variants={prefVariant}
        initial='open'
        // initial='closed'
      >
        <Hint
          title={hintText.ttl}
          text={hintText.body}
          dlUrl={hintText.dlUrl}
          pct={hintText.pct}
          closeFunc={toggleHint}
          cta1txt={hintText.cta1txt}
          isOpen={certHintOpen}
          download={hintText.cta1txt !== null}
        />
        <div className='overlyHeaderCont'>
          <div className="menuNav">
            <Icon type='user'/>
            <span className="CopySmall qTitle">{cmn.your_profile}</span>
          </div>
          <button onClick={closePref} className="closeBt">
            <Icon type='close' />
          </button>
        </div>
        <div className="scoreCont">
          <img src="/pct/result_bg.png" alt={alt1}/>
          <div className="scoreTxTcont">
            <div className='scoreHLine'>{cmn.you_have}</div>
            <div className='scoreTxt'>{points}</div>
            <div className='scoreHLine'>{cmn.cacao_beans}</div>
          </div>
        </div>
        <div className="questionDataCont">
          <div className="qTypeCont">
            <QIcon num={qTotal} isFinished={true}/>
            <div className="qcontLabel">
              <div className="qCount">{qFinished} / {qTotal + 1}</div>
              <div className="qTxtLabel">{cmn.questions}</div>
            </div>
          </div>
          <div className="qTypeCont">
            <Icon type='camera'/>
            <div className="qcontLabel">
              <div className="qCount">{fFinished} / {fTotal}</div>
              <div className="qTxtLabel">{cmn.photobooth}</div>
            </div>
          </div>
        </div>
        {points >= MIN_PTS &&
          <div className="ctasCont">
            <Button text={'your_certificate'} profile cert onClick={() => changePopup('cert')}/>
            <Button text={'your_qr'} profile qr onClick={() => changePopup('discount')}/>
            <Button text={'go_to_raffle'} profile qr onClick={openRaffle}/>
          </div>
        }
        {/* <OverlayHeader rightIcon='menu' text='preference overlay' leftIcon='user' action={closePref} /> */}
      </motion.div>
  )
}

Profile.propTypes = {
  prefOpen: PropTypes.bool,
  alt1: PropTypes.string,
  closePref: PropTypes.func.isRequired,
  qTotal: PropTypes.number.isRequired,
  qFinished: PropTypes.number.isRequired,
  fTotal: PropTypes.number.isRequired,
  fFinished: PropTypes.number.isRequired,
  cta1act: PropTypes.func,
  cta2act: PropTypes.func,
  points: PropTypes.number.isRequired
}

Profile.defaultProps = {

}

export default Profile
