import React, { useState } from 'react'
// import styled from 'styled-components'
// import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import {
  useSelector
  // useDispatch
} from 'react-redux'
import {
  // changeQ,
  selectCurrQ,
  selectQuestions,
  selectPoints,
  selectTotalQs,
  // nextQ,
  // updatePoints,
  selectFloorMenu
} from '../../store/questionSlice'
import { selectOS } from '../../store/appSlice'
import './Debug.css'

const prefVariant = {
  open: { x: 0 },
  closed: { x: '-100%' }
}

const Debug = (props) => {
  const currQ = useSelector(selectCurrQ)
  const qState = useSelector(selectQuestions)
  const floor = qState.currentFloor
  const points = useSelector(selectPoints)
  const floorMenu = useSelector(selectFloorMenu)
  const totalQ = useSelector(selectTotalQs)
  const os = useSelector(selectOS)
  const [debugOpen, setDebugOpen] = useState(false)
  // const dispatch = useDispatch()
  // console.log('totalPoints', points)
  // const currQData = qState.questions[floor].questions[currQ]
  if (!qState.questions) return null
  const currFloorLen = qState?.questions[floor]?.questions?.length || 0
  function toogleDbg () {
    setDebugOpen(!debugOpen)
  }
  return (
    <motion.div
        className="debugWin prefOverlay"
        animate={debugOpen ? 'open' : 'closed'}
        transition={{ type: 'tween' }}
        variants={prefVariant}
        initial='open'>
      <div className="debugCtrl" onClick={toogleDbg}>
        {debugOpen ? 'X' : 'D'}
      </div>
      <div>currFloor: {floor}</div>
      <div>currQ: {currQ} / {currFloorLen}</div>
      <div>Q is finished {floorMenu[floor].questions[currQ]?.isFinished ? 'yep' : 'nope'}</div>
      <div>total point: {points}</div>
      <div>total qs: {totalQ.uQs} / {totalQ.tqs}</div>
      <div>total Pbs: {totalQ.uPbs} / {totalQ.tPbs}</div>
      <div className="os">os is: {os}</div>
      {/* <div>floorQ is finished {JSON.stringify(floorMenu[floor].questions[currQ])}</div> */}
    </motion.div>
  )
}

Debug.propTypes = {

}

Debug.defaultProps = {

}

export default Debug
