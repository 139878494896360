import React, { useState } from 'react'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { selectFloorMenu, selectCurrFloor, selectCurrQ, selectTotalQs, changeFloor, selectPoints } from '../../store/questionSlice'
import './menuOverlay.css'
// import OverlayHeader from '../OverlayHeader/OverlayHeader'
import MenuFootButton from '../MenuFootButton/MenuFootButton'
import FloorMenu from '../FloorMenu/FloorMenu'
import Icon from '../Icon/Icon'
// import Hint from '../Hint/Hint'
import Profile from '../Profile/Profile'
import { selectCommonKeys } from '../../store/appSlice'

// const variantsZ = {
//   open: { x: 0, zIndex: 50 },
//   closed: { x: '100%', zIndex: -1 }
// }
const variants = {
  open: { x: 0, position: 'fixed' },
  closed: { x: '100%', position: 'absolute' }
}
// const prefVariant = {
//   open: { x: 0 },
//   closed: { x: '-100%' }
// }

export default function MenuOverlay ({ isOpen, close }) {
  const [prefOpen, setPrefOpen] = useState(false)
  // const [nextFloor, setNextFloor] = useState(false)
  function openPref () {
    setPrefOpen(true)
  }
  function closePref () {
    setPrefOpen(false)
  }
  const cmn = useSelector(selectCommonKeys)
  const currFloor = useSelector(selectCurrFloor)
  const currQ = useSelector(selectCurrQ)
  const floorMenu = useSelector(selectFloorMenu)
  const totalQ = useSelector(selectTotalQs)
  const points = useSelector(selectPoints)
  const dispatch = useDispatch()

  function _changeFloor (floor) {
    // if (((floor > currFloor) && floorMenu[floor].isFinished === false) && floorMenu[currFloor].isFinished === false) {
    //   setNextFloor(floor)
    // } else {
    dispatch(changeFloor(floor))
    // }
    // dispatch(changeFloor(floor))
  }
  // function forceNextFloor () {
  //   // console.log('force next floor')
  //   setNextFloor(false)
  //   dispatch(changeFloor(nextFloor))
  // }
  // function closeFloorHint () {
  //   setNextFloor(false)
  // }
  return floorMenu && cmn
    ? (
    <motion.div
      className="overlayCont"
      animate={isOpen ? 'open' : 'closed'}
      transition={{ type: 'tween' }}
      variants={variants}
      initial='closed'
    >
      {/* {nextFloor && <Hint title={t('change_floor_title')} text={t('change_floor_warning')} isOpen={!!nextFloor} closeFunc={closeFloorHint} cta1act={forceNextFloor} cta1txt={t('next_floor_confirm')}/>} */}
      <div className="mnu">
        <div className='overlyHeaderCont'>
          <div className="menuNav">
            <Icon type='layer'/>
            <span className={['floorBt', currFloor === 0 ? 'currF' : ''].join(' ')} onClick={() => _changeFloor(0)}>{cmn.floor_0}</span>
            <span className={['floorBt', currFloor === 1 ? 'currF' : ''].join(' ')} onClick={() => _changeFloor(1)}>{cmn.floor_1}</span>
            <span className={['floorBt', currFloor === 2 ? 'currF' : ''].join(' ')} onClick={() => _changeFloor(2)}>{cmn.floor_2}</span>
          </div>
          <button onClick={close} className="closeBt">
            <Icon type='close' />
          </button>
        </div>
        <div className="menuItemsCont">
          <div className="content">
            <FloorMenu menuData={floorMenu[currFloor].questions} close={close} currQ={currQ}/>
          </div>
        </div>
        <div className='overlayFooter'>
          <MenuFootButton type='user' text={'your_profile'} openPref={openPref} />
        </div>
      </div>
      <Profile
        qTotal={totalQ.tqs}
        qFinished={totalQ.uQs}
        fTotal={totalQ.tPbs}
        fFinished={totalQ.uPbs}
        prefOpen={prefOpen}
        points={points}
        closePref={closePref}
      />
    </motion.div>
      )
    : null
}

MenuOverlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
}
