import React from 'react'
import PropTypes from 'prop-types'
import Pictures from '../../Components/Pictures/Pictures'
import Button from '../../Components/Button/Button'
import { useSelector } from 'react-redux'
import { selectCommonKeys } from '../../store/appSlice'

function Page ({ _component, title, body, pct1, pct2, alt1, alt2, _pctOnTop, cta1Txt, cta2Txt, nextPage, nextQuestion, os, kname }) {
  const cmn = useSelector(selectCommonKeys)
  function openFb () {
    nextPage()
    switch (os) {
      case 'Android':
        window.open('intent://page/108548162543053?referrer=app_link#Intent;package=com.facebook.katana;scheme=fb;end')
        break
      case 'iOS':
        window.open('https://www.facebook.com/Schokoladenmuseum/')
        break
      default:
        window.open('https://www.facebook.com/Schokoladenmuseum/')
        break
    }
  }
  function openIg () {
    nextPage()
    switch (os) {
      case 'Android':
        window.open('instagram://user?username=schokoladenmuseumofficial')
        break
      case 'iOS':
        window.open('Instagram://camera')
        break
      default:
        window.open('https://www.instagram.com/schokoladenmuseumofficial/')
        break
    }
  }
  return (
    <div className='pgCont'>
      <div className='pgBody'>
        {pct1 && (
          <Pictures pct1={pct1} alt1={alt1} />
        )}
        <h2>{title}</h2>
        {body && <p>{body}</p>}
      </div>

      <footer className="pgFooter">
        {(_component === 'page' || _component === 'photoPage')
          ? (_component === 'photoPage'
              ? <div className="socialLinks">
                <Button text={'Facebook'} social fb onClick={openFb}/>
                <Button text={'Instagram'} social ig onClick={openIg}/>
                <Button text={cmn.no_social} nope onClick={nextQuestion}/>
              </div>
              : (cta1Txt
                  ? <Button text={cta1Txt} onClick={nextPage}/>
                  : <Button text={cmn.next} onClick={nextPage}/>)
            )
          : (cta1Txt
              ? <Button text={cta1Txt} onClick={nextQuestion}/>
              : <Button text={cmn.next} onClick={nextQuestion}/>
            )
        }
      </footer>
    </div>
  )
}

Page.propTypes = {
  _id: PropTypes.string,
  _component: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  pct1: PropTypes.string,
  pct2: PropTypes.string,
  alt1: PropTypes.string,
  alt2: PropTypes.string,
  cta1Txt: PropTypes.string,
  cta2Txt: PropTypes.string,
  nextPage: PropTypes.func,
  os: PropTypes.string,
  kname: PropTypes.string,
  nextQuestion: PropTypes.func,
  _pctOnTop: PropTypes.bool
}

export default Page
